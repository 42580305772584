.home-card {
  flex: 1 1 100%;
  margin-right: 16px;
  background: $color-white;
  border-radius: 4px;
  padding: 12px 16px;

  &__icon {
    margin-bottom: 12px;
    display: flex;
  }

  h3 {
    margin-bottom: 0;
    color: $color-dark;
    font-size: 16px;
    line-height: 21px;
  }
  
  h5 {
    margin-bottom: 0;
    color: $color-grey;
    font-size: 12px;
    font-weight: normal;
  }
}

.home-card--leaderboard {
  .home-card__icon {
    color: #FF964B;
    font-size: 16px;

    span {
      margin-top: 4px;
      margin-left: 6px;
      line-height: 16px;
      font-weight: bold;
    }
  }
}