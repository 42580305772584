.change-password {
  p {
    font-size: 14px;
    color: $color-dark;
    text-align: center;
  }

  &__submit {
    position: absolute;
    bottom: 0;
    width: 100%;

    @media (min-width: $break-point) {
      position: static;
      padding: 0;
    }
  }
}