* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus {
  outline: 0;
}

html, body {
  background: $color-white;
}

button {
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
}
