.notification {
}

.notif-item {
  padding: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #E7E7E7;

  &--unread {
    background: #D0EFFF;
  }

  p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    color: $color-dark;
  }

  span {
    font-size: 14px;
    line-height: 18px;
    color: $color-grey;
  }
}