.navbar {
  display: block;
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  height: 62px;
  background: $color-white;
  border-bottom: 1px solid $color-green;
  
  &__inner {
    padding: 0 16px;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
  }
}

.navbar-detail-materi {
  justify-content: space-between;
}

.navbar-text {
  position: relative;
  &__text {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    font-weight: 600;
  }
}

.navbar-search {
  &__field {
    display: flex;
    width: 100%;
    margin-left: 12px;
    padding: 12px 20px;
    background: #F7F7F7;
    border: 1px solid #E7E7E7;
    border-radius: 24px;

    input {
      flex: 1 1 100%;
      margin-left: 12px;
      color: $color-dark;
      font-size: 14px;
      border: 0;
      background: transparent;
      line-height: 16px;

      &::placeholder {
        color: $color-grey;
        font-size: 14px;
      }
    }
  }
}