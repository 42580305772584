.comment {
  padding: 16px 0;

  &__info {
    display: flex;
    margin-bottom: 8px;
  }

  &__avatar {
    flex: 0 0 40px;
    margin-right: 8px;
  }

  &__user {
    flex: 1 1 100%;
    margin-top: 8px;
  }

  &__user-name {
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    color: $color-dark;
  }

  &__user-branch {
    font-size: 12px;
    font-weight: 600;
    color: $color-dark;
    opacity: .6;
  }

  &__date {
    flex: 0 0 auto;
    font-size: 12px;
    font-weight: 600;
    color: $color-dark;
    opacity: .6;
  }

  &__content {
    padding-left: 48px;
  }
}

.comment-voice {
  display: flex;
  align-items: center;
  padding: 12px;
  background: #FFF6EE;
  border-radius: 8px;

  &__bar {
    flex: 1 1 100%;
    margin-left: 5px;
    margin-right: 10px;
    background: #000;
    opacity: .2;
    height: 2px;
    border-radius: 1px;
  }

  &__time {
    font-size: 12px;
    font-weight: 600;
    color: $color-dark;
  }
}