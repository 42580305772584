.tab-bar {
  border-radius: 0 0 8px 8px;
  background: $color-white;
  height: 65px;
  
  &__container {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    max-width: $break-point;
  }

  &__item {
    position: relative;
    flex: 1 1 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    color: $color-grey;
    cursor: pointer;


    &--active {
      color: $color-orange;
      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        width: 50%;
        height: 4px;
        background: $color-orange;
        margin: 0 auto;
        border-radius: 8px 8px 0 0;
      }
    }
  }
}