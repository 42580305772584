.leaderboard {
  &.with-navbar {
    padding-top: 127px;
  }
  .tab-bar {
    position: fixed;
    top: 62px;
    width: 100%;
  }
  &__list {
    max-width: $break-point;
    margin: 0 auto;
  }
}

.lb-list-item {
  display: flex;
  height: 76px;
  padding: 0 16px;
  align-items: center;
  color: $color-white;
  font-weight: bold;
  font-size: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  &__index {
    flex: 0 0 32px;
  }

  &__name {
    flex: 1 1 100%;
  }

  &__points {
    flex: 1 0 auto;
    padding: 6px 10px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    font-size: 14px;
    line-height: 18px;
  }
}
