$break-point: 712px;

.container {
  display: block;
  width: 100%;
  max-width: $break-point;
  margin: 0 auto;
  padding: 16px;
}

.container-mobile {
  display: block;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.layout {
  height: 100vh;
  overflow-y: auto;

  &--dark {
    background: $color-blue;
  }

  &--light {
    background: $color-white-grey;
  }

  &--login{
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100%;
  }

}

.with-navbar {
  padding-top: 62px;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.fill-height {
  height: 100%;
}

.divider {
  width: 100%;
  height: 1px;
  background: #EFF2F7;
}