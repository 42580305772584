.auth {
  padding: 24px 16px !important;

  p {
    margin-bottom: 24px;
    font-size: 14px;
    color: $color-dark;
    text-align: center;
  }
}

.auth-login {
  &__logo {
    display: block;
    margin-bottom: 24px;
    width: 120px;
  }

  &__forgot {
    display: block;
    margin-bottom: 20px;
    color: $color-blue;
    font-size: 12px;
    font-weight: bold;
    text-align: right;
  }
}

.auth-forgot {
  &__btn {
    margin-top: 48px;
  }
}

.auth-forgot-confirmed {
  img {
    display: block;
    margin: 0 auto;
    margin-bottom: 24px;
  }
}

.auth-reset {
  &__btn {
    margin-bottom: 30px;
  }
}