@import url('https://fonts.googleapis.com/css?family=Overpass:400,600,700&display=swap');

$font-family:
  'Overpass',
  -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  Roboto,
  Oxygen,
  Ubuntu,
  Cantarell,
  'Open Sans',
  'Helvetica Neue',
  sans-serif;

body {
  font-family: $font-family;
}

a {
  color: inherit;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}
