.home-materi {
  display: flex;
  margin: 16px 0;
  margin-top: 32px;

  &__title {
    flex: 1 1 100%;
    
    h1 {
      margin-bottom: 0;
      font-size: 20px;
      color: $color-white;
    }
    
    h3 {
      margin-bottom: 0;
      color: $color-white;
      opacity: .6;
      font-size: 14px;
    }
  }
}
