.button {
  display: block;
  width: 100%;
  padding: 12px 16px;
  color: white;
  background: $color-orange;
  border-radius: 22px;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;

  &--disabled {
    background: $color-orange-disabled;
    pointer-events: none;
  }

  &:active {
    opacity: 0.5;
  }
}
