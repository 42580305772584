.settings {
  &__avatar {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;

    &-icon {
      position: absolute;
      bottom: 0;
      margin-left: 24px;
    }
  }

  &__form {
    position: relative;

    &__change {
      position: absolute;
      right: 0;
      bottom: 4px;
      color: $color-blue;
      font-size: 14px;
      font-weight: bold;
    }
  }
}