$color-orange: #FF6600;
$color-orange-disabled: #FFA76D;
$color-blue: #006699;
$color-green: #0487A6;
$color-green-dark: #005E74;
$color-dark: #1F2D3D;
$color-grey: #8492A6;
$color-white: #FFFFFF;
$color-white-grey: #F7F7F7;

$colors: (
  color-orange: $color-orange,
  color-orange-disabled: $color-orange-disabled,
  color-blue: $color-blue,
  color-green: $color-green,
  color-green-dark: $color-green-dark,
  color-dark: $color-dark,
  color-grey: $color-grey,
  color-white: $color-white,
  color-white-grey: $color-white-grey
);

// output example: .color-orange, .color-orange-disabled, .color-blue
@mixin colors {
  @each $name, $color in $colors {
    .#{$name} {
      background-color: $color;
    }
    .#{$name}--text {
      color: $color;
    }
  }
}

@include colors;