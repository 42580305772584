.home-materi-card {
  margin-bottom: 16px;
  padding: 20px 16px;
  border-radius: 4px;
  background: $color-white;

  h1 {
    height: 88px;
    overflow: hidden;
    margin: 8px 0 24px;
    color: $color-dark;
    font-size: 20px;
    font-weight: 600;
  }
}