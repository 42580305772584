.quiz {
  .container {
    @media (min-width: $break-point) {
      padding-top: 128px;
    }
  }
}

.quiz-rules {
  position: relative;

  @media (min-width: $break-point) {
    &.card {
      padding: 24px;
    }
  }

  h1 {
    margin-bottom: 16px;
    text-align: center;
    font-size: 22px;
    line-height: 24px;
    color: $color-dark;
  }

  ol {
    padding: 0;
    padding-left: 16px;
    max-width: 424px;
    margin: 0 auto;
    margin-bottom: 20px;

    li {
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 600;
      color: $color-grey;
    }
  }

  &__close {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    margin: 16px;
    @media (min-width: $break-point) {
      margin: 24px;
    }
  }

  &__action {
    max-width: 296px;
    margin: 0 auto;
  }
}

.quiz-quest {
  position: relative;
  text-align: center;
  
  &__paging {
    margin-bottom: 12px;
    text-align: center;
    font-size: 12px;
    color: $color-grey;
  }

  &__timer {
    margin-bottom: 24px;
    display: inline-block;
    padding: 6px 10px;
    border-radius: 15px;
    background: #FFE4E7;
    color: #6B000C;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }

  &__question {
    margin-bottom: 24px;
    height: 112px;
    overflow: hidden;
    font-size: 22px;
    color: $color-dark;
  }

  &__options {
    padding: 0;
    margin: 0 auto;
    list-style: none;
    max-width: 296px;

    li {
      padding: 16px;
      border: 2px solid #E5E9F2;
      border-radius: 28px;
      font-size: 16px;
      font-weight: bold;
      line-height: 16px;
      color: #3C4858;
      margin-bottom: 8px;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }

      &.--selected {
        border-color: $color-orange;
        background: $color-orange;
        color: $color-white;
      }
    }
  }

  &__action {
    position: relative;
    bottom: 0;
    width: 100%;
    margin-top: 24px;
    text-align: center;
    
    &-btn {
      display: inline-block;
    }

    @media (min-width: $break-point) {
      position: static;
    }
  }
}

.quiz-finish {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 12px;
  background: $color-white;
  border-radius: 0;
  text-align: center;
  
  img {
    display: block;
    margin: 0 auto;
    margin-top: 48px;
    margin-bottom: 18px;
  }

  h1 {
    font-size: 20px;
    color: $color-dark;
  }

  h5 {
    margin-bottom: 43px;
    font-weight: 600;
    color: $color-grey;

    span {
      color: $color-orange;
    }
  }

  &__btn {
    position: relative;
    display: block;
    width: 100%;
    height: 60px;
    max-width: 248px;
    margin: 0 auto;
    margin-bottom: 12px;
    background: $color-orange;
    color: $color-white;
    font-weight: bold;
    font-size: 16px;
    border-radius: 4px;

    &-icon {
      position: absolute;
      left: 16px;
    }
  }

  &__btn-close {
    display: block;
    width: 100%;
    height: 60px;
    max-width: 248px;
    margin: 0 auto;
    color: $color-grey;
    font-weight: bold;
    font-size: 16px;
  }

  @media (min-width: $break-point) {
    position: static;
    padding: 24px;
    border-radius: 8px;
    height: auto;

    img {
      margin-top: 0;
    }
  }
}