.text-field {
  margin-bottom: 12px;
  border-bottom: 1px solid $color-green;

  label {
    display: block;
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 15px;
    color: $color-grey;
  }

  input {
    display: block;
    width: 100%;
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    border: 0;
    color: $color-dark;

    &::placeholder {
      color: $color-grey;
    }
  }
}