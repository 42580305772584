.search {
  background: $color-white-grey;
}

.sl-item {
  padding: 16px;
  background: $color-white;
  margin-bottom: 16px;
  border-radius: 4px;

  h2 {
    font-size: 20px;
    font-weight: 600;
    color: $color-dark;
    line-height: 26px;
  }
  h5 {
    font-size: 14px;
    font-weight: 600;
    color: $color-grey;
    line-height: 18px;
  }
}