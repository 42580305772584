.comment-likes {
  line-height: 20px;
  display: flex;
  justify-content: flex-end;

  span {
    display: inline-block;
    margin-left: 16px;
    line-height: 20px;
    color: $color-grey;
    font-size: 14px;
    font-weight: 600;

    img {
      margin-left: 8px;
    }
  }
}