.materi {
  &__title {
    color: $color-dark;
    font-size: 18px;
    font-weight: 600;
  }

  &__content {
    white-space: pre-line;
    color: $color-grey;
  }

  &__btn-start {
    padding: 12px;
    margin: 24px 0;
    background: $color-orange;
    border-radius: 4px;
    color: $color-white;

    strong, small {
      display: block;
      color: $color-white;
      text-align: center;
    }

    strong {
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
    }
    
    small {
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      opacity: .6;
    }
  }
}