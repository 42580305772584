@import 'vendors/reboot';

@import 'global/colors';
@import 'global/elements';
@import 'global/layout';
@import 'global/typography';

@import 'components/auth';
@import 'components/avatar';
@import 'components/button';
@import 'components/button-comment';
@import 'components/button-back';
@import 'components/button-hex';
@import 'components/button-next';
@import 'components/card';
@import 'components/change-password';
@import 'components/comment';
@import 'components/comment-input';
@import 'components/comment-likes';
@import 'components/date-label';
@import 'components/home-card';
@import 'components/home-card-group';
@import 'components/home-hello';
@import 'components/home-materi';
@import 'components/home-materi-card';
@import 'components/leaderboard';
@import 'components/materi';
@import 'components/navbar';
@import 'components/notification';
@import 'components/search';
@import 'components/settings';
@import 'components/tab-bar';
@import 'components/text-field';
@import 'components/quiz';

body {
  background-color: white !important;
}
