.date-label {
  display: flex;

  &__date {
    padding: 6px 10px;
    border-radius: 15px;
    color: $color-grey;
    background: #E0E6ED;
    font-size: 14px;
    line-height: 14px;
  }
  
  &__new {
    padding: 6px 10px;
    margin-left: 8px;
    color: #6B000C;
    background: #FFE4E7;
    border-radius: 15px;
    font-size: 14px;
    line-height: 14px;
  }
}
