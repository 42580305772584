@import url(https://fonts.googleapis.com/css?family=Overpass:400,600,700&display=swap);
/*!
 * Bootstrap Reboot v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

a:hover {
  color: #0056b3;
  text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

/*# sourceMappingURL=bootstrap-reboot.css.map */
.color-orange {
  background-color: #FF6600; }

.color-orange--text {
  color: #FF6600; }

.color-orange-disabled {
  background-color: #FFA76D; }

.color-orange-disabled--text {
  color: #FFA76D; }

.color-blue {
  background-color: #006699; }

.color-blue--text {
  color: #006699; }

.color-green {
  background-color: #0487A6; }

.color-green--text {
  color: #0487A6; }

.color-green-dark {
  background-color: #005E74; }

.color-green-dark--text {
  color: #005E74; }

.color-dark {
  background-color: #1F2D3D; }

.color-dark--text {
  color: #1F2D3D; }

.color-grey {
  background-color: #8492A6; }

.color-grey--text {
  color: #8492A6; }

.color-white {
  background-color: #FFFFFF; }

.color-white--text {
  color: #FFFFFF; }

.color-white-grey {
  background-color: #F7F7F7; }

.color-white-grey--text {
  color: #F7F7F7; }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

*:focus {
  outline: 0; }

html, body {
  background: #FFFFFF; }

button {
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
  cursor: pointer; }
  button:focus {
    outline: 0; }

.container {
  display: block;
  width: 100%;
  max-width: 712px;
  margin: 0 auto;
  padding: 16px; }

.container-mobile {
  display: block;
  width: 100%;
  max-width: 300px;
  margin: 0 auto; }

.layout {
  height: 100vh;
  overflow-y: auto; }
  .layout--dark {
    background: #006699; }
  .layout--light {
    background: #F7F7F7; }
  .layout--login {
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100%; }

.with-navbar {
  padding-top: 62px; }

.flex {
  display: flex; }

.align-center {
  align-items: center; }

.fill-height {
  height: 100%; }

.divider {
  width: 100%;
  height: 1px;
  background: #EFF2F7; }

body {
  font-family: "Overpass", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; }

a {
  color: inherit; }
  a:hover {
    text-decoration: none;
    color: inherit; }

.auth {
  padding: 24px 16px !important; }
  .auth p {
    margin-bottom: 24px;
    font-size: 14px;
    color: #1F2D3D;
    text-align: center; }

.auth-login__logo {
  display: block;
  margin-bottom: 24px;
  width: 120px; }

.auth-login__forgot {
  display: block;
  margin-bottom: 20px;
  color: #006699;
  font-size: 12px;
  font-weight: bold;
  text-align: right; }

.auth-forgot__btn {
  margin-top: 48px; }

.auth-forgot-confirmed img {
  display: block;
  margin: 0 auto;
  margin-bottom: 24px; }

.auth-reset__btn {
  margin-bottom: 30px; }

.avatar {
  border-radius: 100%;
  overflow: hidden; }
  .avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.button {
  display: block;
  width: 100%;
  padding: 12px 16px;
  color: white;
  background: #FF6600;
  border-radius: 22px;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px; }
  .button--disabled {
    background: #FFA76D;
    pointer-events: none; }
  .button:active {
    opacity: 0.5; }

.button-comment {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 16px; }
  .button-comment:active {
    opacity: 0.5; }

.button-back:active {
  opacity: 0.5; }

.button-hex {
  cursor: pointer; }
  .button-hex:active {
    opacity: 0.5; }

.button-next:active {
  opacity: .6; }

.card {
  padding: 16px;
  border-radius: 4px;
  background: #FFFFFF; }
  .card--rounder {
    border-radius: 12px; }

.change-password p {
  font-size: 14px;
  color: #1F2D3D;
  text-align: center; }

.change-password__submit {
  position: absolute;
  bottom: 0;
  width: 100%; }
  @media (min-width: 712px) {
    .change-password__submit {
      position: static;
      padding: 0; } }

.comment {
  padding: 16px 0; }
  .comment__info {
    display: flex;
    margin-bottom: 8px; }
  .comment__avatar {
    flex: 0 0 40px;
    margin-right: 8px; }
  .comment__user {
    flex: 1 1 100%;
    margin-top: 8px; }
  .comment__user-name {
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    color: #1F2D3D; }
  .comment__user-branch {
    font-size: 12px;
    font-weight: 600;
    color: #1F2D3D;
    opacity: .6; }
  .comment__date {
    flex: 0 0 auto;
    font-size: 12px;
    font-weight: 600;
    color: #1F2D3D;
    opacity: .6; }
  .comment__content {
    padding-left: 48px; }

.comment-voice {
  display: flex;
  align-items: center;
  padding: 12px;
  background: #FFF6EE;
  border-radius: 8px; }
  .comment-voice__bar {
    flex: 1 1 100%;
    margin-left: 5px;
    margin-right: 10px;
    background: #000;
    opacity: .2;
    height: 2px;
    border-radius: 1px; }
  .comment-voice__time {
    font-size: 12px;
    font-weight: 600;
    color: #1F2D3D; }

.comment-input-overlay {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.64); }

.comment-input {
  position: relative;
  height: 100%;
  padding: 16px;
  background: #006699;
  text-align: center; }
  @media (min-width: 712px) {
    .comment-input {
      height: 480px;
      padding: 24px;
      border-radius: 8px; } }
  .comment-input h1 {
    font-size: 12px;
    font-weight: 600;
    color: #FFFFFF; }
  .comment-input textarea {
    width: 100%;
    height: 208px;
    padding: 0;
    text-align: center;
    background: transparent;
    border: 0;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 600; }
    .comment-input textarea::-webkit-input-placeholder {
      opacity: .6;
      color: #FFFFFF; }
    .comment-input textarea:-ms-input-placeholder {
      opacity: .6;
      color: #FFFFFF; }
    .comment-input textarea::placeholder {
      opacity: .6;
      color: #FFFFFF; }
  .comment-input__actions {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 16px;
    list-style: none;
    justify-content: space-between; }
    @media (min-width: 712px) {
      .comment-input__actions {
        padding: 24px; } }
    .comment-input__actions li {
      flex: 1 1 100%; }
      .comment-input__actions li:nth-child(1) {
        text-align: left; }
      .comment-input__actions li:nth-child(3) {
        text-align: right; }

.comment-input-cancel {
  display: inline-block;
  padding: 11px 23px;
  border: 2px solid rgba(255, 255, 255, 0.6);
  border-radius: 24px;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer; }
  .comment-input-cancel:active {
    opacity: .6; }

.comment-input-attachment:active {
  opacity: .6; }

.comment-likes {
  line-height: 20px;
  display: flex;
  justify-content: flex-end; }
  .comment-likes span {
    display: inline-block;
    margin-left: 16px;
    line-height: 20px;
    color: #8492A6;
    font-size: 14px;
    font-weight: 600; }
    .comment-likes span img {
      margin-left: 8px; }

.date-label {
  display: flex; }
  .date-label__date {
    padding: 6px 10px;
    border-radius: 15px;
    color: #8492A6;
    background: #E0E6ED;
    font-size: 14px;
    line-height: 14px; }
  .date-label__new {
    padding: 6px 10px;
    margin-left: 8px;
    color: #6B000C;
    background: #FFE4E7;
    border-radius: 15px;
    font-size: 14px;
    line-height: 14px; }

.home-card {
  flex: 1 1 100%;
  margin-right: 16px;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 12px 16px; }
  .home-card__icon {
    margin-bottom: 12px;
    display: flex; }
  .home-card h3 {
    margin-bottom: 0;
    color: #1F2D3D;
    font-size: 16px;
    line-height: 21px; }
  .home-card h5 {
    margin-bottom: 0;
    color: #8492A6;
    font-size: 12px;
    font-weight: normal; }

.home-card--leaderboard .home-card__icon {
  color: #FF964B;
  font-size: 16px; }
  .home-card--leaderboard .home-card__icon span {
    margin-top: 4px;
    margin-left: 6px;
    line-height: 16px;
    font-weight: bold; }

.home-card-group {
  display: flex;
  margin-right: -16px; }

.home-hello {
  display: flex;
  margin: 8px 0 24px; }
  .home-hello__avatar {
    flex: 0 0 auto; }
  .home-hello__name {
    flex: 1 1 100%;
    margin-top: 4px;
    margin-left: 12px;
    overflow: hidden;
    white-space: nowrap; }
    .home-hello__name small {
      color: #FFFFFF;
      font-size: 14px;
      opacity: .8; }
    .home-hello__name h2 {
      margin-bottom: 0;
      color: #FFFFFF;
      font-size: 18px; }
  .home-hello__btn {
    flex: 0 0 auto;
    margin-top: 6px; }

.home-materi {
  display: flex;
  margin: 16px 0;
  margin-top: 32px; }
  .home-materi__title {
    flex: 1 1 100%; }
    .home-materi__title h1 {
      margin-bottom: 0;
      font-size: 20px;
      color: #FFFFFF; }
    .home-materi__title h3 {
      margin-bottom: 0;
      color: #FFFFFF;
      opacity: .6;
      font-size: 14px; }

.home-materi-card {
  margin-bottom: 16px;
  padding: 20px 16px;
  border-radius: 4px;
  background: #FFFFFF; }
  .home-materi-card h1 {
    height: 88px;
    overflow: hidden;
    margin: 8px 0 24px;
    color: #1F2D3D;
    font-size: 20px;
    font-weight: 600; }

.leaderboard.with-navbar {
  padding-top: 127px; }

.leaderboard .tab-bar {
  position: fixed;
  top: 62px;
  width: 100%; }

.leaderboard__list {
  max-width: 712px;
  margin: 0 auto; }

.lb-list-item {
  display: flex;
  height: 76px;
  padding: 0 16px;
  align-items: center;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
  .lb-list-item__index {
    flex: 0 0 32px; }
  .lb-list-item__name {
    flex: 1 1 100%; }
  .lb-list-item__points {
    flex: 1 0 auto;
    padding: 6px 10px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    font-size: 14px;
    line-height: 18px; }

.materi__title {
  color: #1F2D3D;
  font-size: 18px;
  font-weight: 600; }

.materi__content {
  white-space: pre-line;
  color: #8492A6; }

.materi__btn-start {
  padding: 12px;
  margin: 24px 0;
  background: #FF6600;
  border-radius: 4px;
  color: #FFFFFF; }
  .materi__btn-start strong, .materi__btn-start small {
    display: block;
    color: #FFFFFF;
    text-align: center; }
  .materi__btn-start strong {
    font-size: 18px;
    font-weight: bold;
    line-height: 23px; }
  .materi__btn-start small {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    opacity: .6; }

.navbar {
  display: block;
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  height: 62px;
  background: #FFFFFF;
  border-bottom: 1px solid #0487A6; }
  .navbar__inner {
    padding: 0 16px;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center; }

.navbar-detail-materi {
  justify-content: space-between; }

.navbar-text {
  position: relative; }
  .navbar-text__text {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    font-weight: 600; }

.navbar-search__field {
  display: flex;
  width: 100%;
  margin-left: 12px;
  padding: 12px 20px;
  background: #F7F7F7;
  border: 1px solid #E7E7E7;
  border-radius: 24px; }
  .navbar-search__field input {
    flex: 1 1 100%;
    margin-left: 12px;
    color: #1F2D3D;
    font-size: 14px;
    border: 0;
    background: transparent;
    line-height: 16px; }
    .navbar-search__field input::-webkit-input-placeholder {
      color: #8492A6;
      font-size: 14px; }
    .navbar-search__field input:-ms-input-placeholder {
      color: #8492A6;
      font-size: 14px; }
    .navbar-search__field input::placeholder {
      color: #8492A6;
      font-size: 14px; }

.notif-item {
  padding: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #E7E7E7; }
  .notif-item--unread {
    background: #D0EFFF; }
  .notif-item p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    color: #1F2D3D; }
  .notif-item span {
    font-size: 14px;
    line-height: 18px;
    color: #8492A6; }

.search {
  background: #F7F7F7; }

.sl-item {
  padding: 16px;
  background: #FFFFFF;
  margin-bottom: 16px;
  border-radius: 4px; }
  .sl-item h2 {
    font-size: 20px;
    font-weight: 600;
    color: #1F2D3D;
    line-height: 26px; }
  .sl-item h5 {
    font-size: 14px;
    font-weight: 600;
    color: #8492A6;
    line-height: 18px; }

.settings__avatar {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 24px; }
  .settings__avatar-icon {
    position: absolute;
    bottom: 0;
    margin-left: 24px; }

.settings__form {
  position: relative; }
  .settings__form__change {
    position: absolute;
    right: 0;
    bottom: 4px;
    color: #006699;
    font-size: 14px;
    font-weight: bold; }

.tab-bar {
  border-radius: 0 0 8px 8px;
  background: #FFFFFF;
  height: 65px; }
  .tab-bar__container {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    max-width: 712px; }
  .tab-bar__item {
    position: relative;
    flex: 1 1 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    color: #8492A6;
    cursor: pointer; }
    .tab-bar__item--active {
      color: #FF6600; }
      .tab-bar__item--active::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        width: 50%;
        height: 4px;
        background: #FF6600;
        margin: 0 auto;
        border-radius: 8px 8px 0 0; }

.text-field {
  margin-bottom: 12px;
  border-bottom: 1px solid #0487A6; }
  .text-field label {
    display: block;
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 15px;
    color: #8492A6; }
  .text-field input {
    display: block;
    width: 100%;
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    border: 0;
    color: #1F2D3D; }
    .text-field input::-webkit-input-placeholder {
      color: #8492A6; }
    .text-field input:-ms-input-placeholder {
      color: #8492A6; }
    .text-field input::placeholder {
      color: #8492A6; }

@media (min-width: 712px) {
  .quiz .container {
    padding-top: 128px; } }

.quiz-rules {
  position: relative; }
  @media (min-width: 712px) {
    .quiz-rules.card {
      padding: 24px; } }
  .quiz-rules h1 {
    margin-bottom: 16px;
    text-align: center;
    font-size: 22px;
    line-height: 24px;
    color: #1F2D3D; }
  .quiz-rules ol {
    padding: 0;
    padding-left: 16px;
    max-width: 424px;
    margin: 0 auto;
    margin-bottom: 20px; }
    .quiz-rules ol li {
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 600;
      color: #8492A6; }
  .quiz-rules__close {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    margin: 16px; }
    @media (min-width: 712px) {
      .quiz-rules__close {
        margin: 24px; } }
  .quiz-rules__action {
    max-width: 296px;
    margin: 0 auto; }

.quiz-quest {
  position: relative;
  text-align: center; }
  .quiz-quest__paging {
    margin-bottom: 12px;
    text-align: center;
    font-size: 12px;
    color: #8492A6; }
  .quiz-quest__timer {
    margin-bottom: 24px;
    display: inline-block;
    padding: 6px 10px;
    border-radius: 15px;
    background: #FFE4E7;
    color: #6B000C;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px; }
  .quiz-quest__question {
    margin-bottom: 24px;
    height: 112px;
    overflow: hidden;
    font-size: 22px;
    color: #1F2D3D; }
  .quiz-quest__options {
    padding: 0;
    margin: 0 auto;
    list-style: none;
    max-width: 296px; }
    .quiz-quest__options li {
      padding: 16px;
      border: 2px solid #E5E9F2;
      border-radius: 28px;
      font-size: 16px;
      font-weight: bold;
      line-height: 16px;
      color: #3C4858;
      margin-bottom: 8px;
      cursor: pointer; }
      .quiz-quest__options li:last-child {
        margin-bottom: 0; }
      .quiz-quest__options li.--selected {
        border-color: #FF6600;
        background: #FF6600;
        color: #FFFFFF; }
  .quiz-quest__action {
    position: relative;
    bottom: 0;
    width: 100%;
    margin-top: 24px;
    text-align: center; }
    .quiz-quest__action-btn {
      display: inline-block; }
    @media (min-width: 712px) {
      .quiz-quest__action {
        position: static; } }

.quiz-finish {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 12px;
  background: #FFFFFF;
  border-radius: 0;
  text-align: center; }
  .quiz-finish img {
    display: block;
    margin: 0 auto;
    margin-top: 48px;
    margin-bottom: 18px; }
  .quiz-finish h1 {
    font-size: 20px;
    color: #1F2D3D; }
  .quiz-finish h5 {
    margin-bottom: 43px;
    font-weight: 600;
    color: #8492A6; }
    .quiz-finish h5 span {
      color: #FF6600; }
  .quiz-finish__btn {
    position: relative;
    display: block;
    width: 100%;
    height: 60px;
    max-width: 248px;
    margin: 0 auto;
    margin-bottom: 12px;
    background: #FF6600;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 16px;
    border-radius: 4px; }
    .quiz-finish__btn-icon {
      position: absolute;
      left: 16px; }
  .quiz-finish__btn-close {
    display: block;
    width: 100%;
    height: 60px;
    max-width: 248px;
    margin: 0 auto;
    color: #8492A6;
    font-weight: bold;
    font-size: 16px; }
  @media (min-width: 712px) {
    .quiz-finish {
      position: static;
      padding: 24px;
      border-radius: 8px;
      height: auto; }
      .quiz-finish img {
        margin-top: 0; } }

body {
  background-color: white !important; }

