.comment-input-overlay {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .64);
}

.comment-input {
  position: relative;
  height: 100%;
  padding: 16px;
  background: $color-blue;
  text-align: center;
  
  @media (min-width: $break-point) {
    height: 480px;
    padding: 24px;
    border-radius: 8px;
  }

  h1 {
    font-size: 12px;
    font-weight: 600;
    color: $color-white;
  }

  textarea {
    width: 100%;
    height: 208px;
    padding: 0;
    text-align: center;
    background: transparent;
    border: 0;
    color: $color-white;
    font-size: 20px;
    font-weight: 600;

    &::placeholder {
      opacity: .6;
      color: $color-white;
    }
  }

  &__actions {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 16px;
    list-style: none;
    justify-content: space-between;

    @media (min-width: $break-point) {
      padding: 24px;
    }

    li {
      flex: 1 1 100%;

      &:nth-child(1) {
        text-align: left;
      }

      &:nth-child(3) {
        text-align: right;
      }
    }
  }
}

.comment-input-cancel {
  display: inline-block;
  padding: 11px 23px;
  border: 2px solid rgba(255, 255, 255, .6);
  border-radius: 24px;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  color: rgba(255, 255, 255, .6);
  cursor: pointer;

  &:active {
    opacity: .6;
  }
}

.comment-input-attachment {
  &:active {
    opacity: .6;
  }
}