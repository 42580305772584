.home-hello {
  display: flex;
  margin: 8px 0 24px;

  &__avatar {
    flex: 0 0 auto;
  }

  &__name {
    flex: 1 1 100%;
    margin-top: 4px;
    margin-left: 12px;
    overflow: hidden;
    white-space: nowrap;
    
    small {
      color: $color-white;
      font-size: 14px;
      opacity: .8;
    }
    
    h2 {
      margin-bottom: 0;
      color: $color-white;
      font-size: 18px;
    }
  }

  &__btn {
    flex: 0 0 auto;
    margin-top: 6px;
  }
}